<div class="modal">
    <div class="modal-content">
      <div class="d-flex flex-column align-items-start justify-content-start px-3 pb-3">
        <p class="modal-title mb-3">{{ title }}</p>
        <p class="modal-subtitle">{{ subTitle }}</p>
      </div>
      <div class="buttons-container d-flex w-100  p-3">
        <button (click)="onConfirm()" mat-raised-button class="text-white w-100" [class]="markDone ? 'success-bg' : 'dangerous-bg'" >
          {{"confirm" | translate}}
        </button>
        <button (click)="onCancel()" mat-raised-button class="cancel-btn w-100" >
          {{"cancel" | translate}}
        </button>
      </div>
    </div>
  </div>
  