import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-co-table-pagination',
  templateUrl: './co-table-pagination.component.html',
  styleUrls: ['./co-table-pagination.component.scss'],
})
export class CoTablePaginationComponent {
  @Input() length: number = 0; // Total number of items
  @Input() pageSize: number = 10; // Items per page
  @Input() pageIndex: number = 0; // Current page index (zero-based)
  @Input() pageSizeOptions: number[] = [10, 20, 50, 100, 200]; // Options for items per page

  @Output() pageChange = new EventEmitter<{ pageIndex: number; pageSize: number }>();

  // Emit page change event to the parent component
  handlePageChange(event: any): void {
    this.pageChange.emit({ pageIndex: event.pageIndex, pageSize: event.pageSize });
  }
}
