import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss'],
})
export class ImagePopupComponent {
  @Input() imageUrl: string | null = null; // Image URL to display
  @Input() isOpen = false; // Whether the popup is open or not
  @Input() imgList: string[] = []; // List of images to navigate through
  @Output() close = new EventEmitter<void>(); // Emit event to close the popup

  currentIndex = 0; // Track the current image index

  closePopup() {
    this.close.emit();
  }

  openImage(image: string) {
    this.imageUrl = image;
    this.currentIndex = this.imgList.indexOf(image);
  }

  nextImage() {
    if (this.imgList.length > 1) {
      this.currentIndex = (this.currentIndex + 1) % this.imgList.length;
      this.imageUrl = this.imgList[this.currentIndex];
    }
  }

  previousImage() {
    if (this.imgList.length > 1) {
      this.currentIndex = (this.currentIndex - 1 + this.imgList.length) % this.imgList.length;
      this.imageUrl = this.imgList[this.currentIndex];
    }
  }
}
