import { RoleActionGuard } from './services/api/login/role-action.guard';
import { NgModule } from '@angular/core';
//import { RouterModule, Routes } from '@angular/router';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './services/api/login/login.guard';
import { SystemPageActions } from './services/system-page-actions.enum';

const routes: Routes = [
  // Default path (redirect to login)
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  // Dynamic compoundId route with child routes
  {
    path: ':compoundId',  // Dynamic parent route with compoundId
    loadChildren: () =>
      import('./pages/compound-layout/compound-layout.module').then(
        (m) => m.CompoundLayoutModule
      ),
  },
  // Dynamic Mnagement route with child routes
  {
    path: 'management',
    loadChildren: () =>
      import('./pages/management-layout/management-layout.module').then(
        (m) => m.ManagementLayoutModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./pages/accounts/accounts.module').then((m) => m.AccountsModule),
  },




  // {
  //   path: '404',
  //   component: NotFoundComponent
  // },

  // {
  //   path: '**',
  //   redirectTo: '404'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
