import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { statusesFilter } from 'src/app/services/api/admin/compounds.service';

@Component({
  selector: 'app-co-status-request-filters',
  templateUrl: './co-status-request-filters.component.html',
  styleUrls: ['./co-status-request-filters.component.scss']
})
export class CoStatusRequestFiltersComponent {

  @Input() totalItems: number = 0; // Total number of items
  @Input() requestsFilter: any;
  @Input() title: string='';

  
  @Input() statuses: statusesFilter[] = [];
  @Output() filterChange = new EventEmitter<number | null>();





  onFilterByStatus(status: number | null): void {
    this.filterChange.emit(status);
  }}