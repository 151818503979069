import { Component, OnInit } from '@angular/core';
import { CoSpinnerService } from './co-spinner.service';

@Component({
  selector: 'app-co-spinner',
  templateUrl: './co-spinner.component.html',
  styleUrls: ['./co-spinner.component.scss']
})
export class CoSpinnerComponent implements OnInit {
  isLoading = false;

  constructor(private spinnerService: CoSpinnerService) {
    this.spinnerService.isLoading$.subscribe(
      (isLoading) => (this.isLoading = isLoading)
    );
  }

  ngOnInit(): void {
  }

}
