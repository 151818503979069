import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoSpinnerService } from 'src/app/shared/co-spinner/co-spinner.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  
  constructor(
    private spinnerService: CoSpinnerService,
    private snackBar: MatSnackBar,// Inject Angular Material SnackBar
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.show(); // Show spinner before processing the request

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // Display an error message in the snack bar
        const errorMessage = this.getErrorMessage(error);
        this.snackBar.open(errorMessage, 'Close', {
          duration: 5000, // Duration in milliseconds
          panelClass: ['snackbar-error'], // Add a custom class for styling
        });

        // Log the error to the console
        console.error('HTTP Error:', error);

        // Re-throw the error so other parts of the app can handle it if necessary
        return throwError(() => new Error(errorMessage));
      }),
      finalize(() => {
        // Hide the spinner after request completes, whether successful or failed
        this.spinnerService.hide();
      })
    );
  }

  // Helper method to extract user-friendly error messages
  private getErrorMessage(error: HttpErrorResponse): string {
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      return `Client error: ${error.error.message}`;
    } else {
      // Server-side error
      return `Server error: ${error.status} - ${error.message || 'Unknown error occurred'}`;
    }
  }
}
