<div class="search-areas mb-3">
  <div class="d-flex flex-wrap justify-content-between search-area">
    <div class="col-md-8 search-input">
      <mat-form-field class="matformfield w-100" appearance="outline">
        <mat-icon matPrefix class="ml-1 search-icon">search</mat-icon>
        <input matInput [(ngModel)]="searchText" name="searchText" (keyup.enter)="onSearch()"
          matTooltip="  {{ 'owners.list.searchHint' | translate }}  " matTooltipPosition="above" autocomplete="off"
          placeholder="{{ placeholder | translate }}" />
      </mat-form-field>
    </div>

    <div class="advanced-filter" *ngIf="showAdvancedFilter" (click)="openCloseFilters()">
      <span class="mt-3">
        <mat-icon matTooltip="Advanced Filter" matTooltipPosition="above" class="ml-1 filter-icon-opened"
          *ngIf="!showFilter">keyboard_double_arrow_down</mat-icon>
        <mat-icon matTooltip="Advanced Filter" matTooltipPosition="above" class="ml-1 filter-icon-closed"
          *ngIf="showFilter">keyboard_double_arrow_up</mat-icon>
      </span>
      <span class="mt-2">{{ "SEARCH.AdvancedFilter" | translate }}</span>
    </div>
  </div>
  <mat-card class="mat-elevation-z4 search-creteria" *ngIf="showFilter">
    <div class="fluid-container">
      <ng-content></ng-content>
    </div>
  </mat-card>
</div>