<div class="d-flex align-items-center flex-wrap mb-4">
    <div class="filters-header">
        <span class="filters-total">{{ totalItems }}</span>
        {{ title | translate }}
      </div>
    <div
      *ngFor="let status of statuses"
      class="d-flex align-items-center m-2"
      [class.active-filter]="requestsFilter?.status === status.value"
      [class.dimmed-filter]="requestsFilter?.status !== status.value"
      (click)="onFilterByStatus(status.value)"
    >
      {{ status.label | translate }}
      <span
        [class.active-filter-number]="requestsFilter?.status === status.value"
        [class.dimmed-filter-number]="requestsFilter?.status !== status.value"
      >
        {{ status.count }}
      </span>
    </div>
  </div>