import { OnInit, Input, Output, Inject, EventEmitter, Component } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: 'app-co-modal', 
  templateUrl: './co-modal.component.html',
  styleUrls: ['./co-modal.component.scss'],
})
export class CoModalComponent implements OnInit {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() markDone: boolean = true;
  @Output() close = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<CoModalComponent>
) {
    if (data) {
      this.title = data.title;
      this.subTitle = data.subTitle;
      this.markDone = data.markDone;
    }
  }

  onConfirm() {
    this.close.emit(true);
    this.dialogRef.close(true);
  }

  onCancel() {
    this.close.emit(false); 
    this.dialogRef.close(false); 
  }
  ngOnInit(): void {}
}
