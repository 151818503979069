import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-area',
  templateUrl: './search-area.component.html',
  styleUrls: ['./search-area.component.scss']
})
export class SearchAreaComponent {
  @Input() placeholder: string = 'issues.list.search'; // Placeholder text for translation
  @Input() searchText: string = ''; // Binding for search input
  @Output() search = new EventEmitter<string>(); // Output event for search
  @Input() showFilter: boolean = false; // Show/hide advanced filter
  @Input() showAdvancedFilter: boolean = false;
  onSearch(): void {
    this.search.emit(this.searchText); // Emit search text
  }

  openCloseFilters(): void {
    this.showFilter = !this.showFilter;
  }
}
